import {FaBuilding, FaCog, FaCogs, FaCreditCard, FaList, FaNetworkWired} from "react-icons/fa";
import {IconType} from "react-icons";

export const menuList:{
    id: string
    url: string
    icon: IconType
    name: string
    disabled: boolean
    children?: any[]
}[] = [
    {id: 'reading', url: '/reading', icon: FaList, name: '의뢰검사조회', disabled: false},
    /*{id: 'manage', url: '/manage/center', icon: FaCog  , name: '관리', disabled: false,
        children: [
            {id: 'center', url: '/manage/center', icon: FaBuilding, name: '센터 관리', disabled: false},
            {id: 'adminUser', url: '/manage/center', icon: FaNetworkWired , name: '관리자 관리', disabled: false},
            {id: 'user', url: '/manage/center', icon: FaList, name: '사용자 관리', disabled: false},
            {id: 'pay', url: '/manage/center', icon: FaList, name: '판독료 관리', disabled: false}
        ]
    },*/
    // {id: 'payment', url: '/payment', icon: FaCreditCard, name: '정산조회', disabled: false},
    // {id: 'viewer', url: '/viewer', icon: FaCreditCard, name: '뷰어테스트', disabled: false},
]
