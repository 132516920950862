import React from 'react'
import { FaRegBell } from "react-icons/fa6";
import { BsPersonCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import {useUserLogout} from "@query/UserQuery";

function Header() {
    const {mutate: userLogout} = useUserLogout()
    const onLogout = (e:React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        userLogout();
    }
  return (
    <div className="header_wrap">
      <div className="logo">
        <Link to={"/"} className="mainLink">
          Inviz PACS Manager
        </Link>
      </div>
      <div className="header_right">
        {/* <div className="bell_btn">
          <FaRegBell />
        </div> */}
        <div className="dropdown" onClick={onLogout}>
          <div className="person_btn" ><BsPersonCircle /></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
