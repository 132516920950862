import { ComponentType, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '@store/user/store';
import { Toast } from '@lib/Toas';

/**
 *
 * @param SpecificComponent 권한이 올바른 경우 보여줄 component
 * @param option 페이지 이동 여부
 * @param adminRoute null: 누구나 접근 가능, true: 관리자만 로그인 가능, false: 로그인한 인원 전체 접근 불가
 * @constructor
 */
export default function Auth(SpecificComponent: ComponentType, option: boolean = false, adminRoute: boolean | null = null) {
  function AuthenticationCheck() {
    const navigate = useNavigate();
    const token = useUserData((state) => state.token);
    useLayoutEffect(() => {
      const isLogin = !!token;
      const isManager = false;
      if (!isLogin) { // 로그인 하지 않는 상태
        if (option) {
          Toast('로그인 후 이용 가능합니다.', 'warning');
          navigate('/login', { replace: true });
        }
      } else { // 로그인 상태
        // 관리자 페이지 이면서 관리자가 아닌경우
        if (adminRoute && !isManager) {
          navigate('/', { replace: true });
        }
        // if (!option) navigate('/', { replace: true });
      }
      // 로그인 todo
      // navigate('/', { replace: true });

    }, [token]);
    return <SpecificComponent/>;
  }

  return AuthenticationCheck;
}
