import Swal, {SweetAlertResult, SweetAlertIcon, SweetAlertOptions} from 'sweetalert2';

export const CustomConfirm = (title?: string, text?: string, icon?: SweetAlertIcon, callback?: (result: SweetAlertResult) => any, useCancel: boolean = true) => {
    let defaultCallback = (result: SweetAlertResult) => {

    };
    if (callback) defaultCallback = callback;
    const defaultOption: SweetAlertOptions = {
        showCancelButton: useCancel,
        width: '410px',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
    };
    if (icon) defaultOption.icon = icon;
    if (title) defaultOption.title = title;
    if (text) defaultOption.html = `<div style="font-size:15px">${text}</div>`;

    Swal.fire(defaultOption).then(defaultCallback);
};
export const CustomConfirm2 = (title?: string, text?: string, icon?: SweetAlertIcon, callback?: (result: SweetAlertResult) => any, useCancel: boolean = true) => {
    let defaultCallback = (result: SweetAlertResult) => {

    };
    if (callback) defaultCallback = callback;
    const defaultOption: SweetAlertOptions = {
        showCancelButton: useCancel,
        width: '410px',
        input: 'text',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        customClass: {container: 'v_annotation_input'},
    };
    if (icon) defaultOption.icon = icon;
    if (title) defaultOption.title = title;
    if (text) defaultOption.html = `<div style="font-size:15px">${text}</div>`;
    Swal.fire(defaultOption).then(defaultCallback);
};
