import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {AxiosInterceptor} from "@lib/axios";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ErrorBoundary} from "react-error-boundary";
import {BrowserRouter} from "react-router-dom";
import Error from "@pages/error/Error"
import {ToastContainer} from "react-toastify";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    },
});
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <QueryClientProvider client={queryClient}>
        <ErrorBoundary fallbackRender={Error}
                       onReset={(details) => {
                           console.log(details)
                           // Reset the state of your app so the error doesn't happen again
                       }}>
            <BrowserRouter>
                <AxiosInterceptor>
                    <App />
                </AxiosInterceptor>
            </BrowserRouter>
        </ErrorBoundary>
        <ToastContainer/>
    </QueryClientProvider>
);
