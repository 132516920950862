import React, { useCallback, useMemo } from "react";
import {Link, Outlet} from 'react-router-dom'
import Header from "@pages/workList/Header";
import {useState} from "react";
import {menuList} from "@layout/menu";
import classNames from "classnames";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

export default function Layout() {
    const [activeMenu, setActiveMenu] = useState(["reading"]);
    const memoizedMenuList = useMemo(() => menuList, [menuList]);

    const handleMenuClick = useCallback((menu: string) => (/*e:React.MouseEvent<HTMLAnchorElement>*/) => {
        setActiveMenu(prevState => {
            if (prevState.includes(menu)) {
                return prevState.filter(item=> item !== menu);
            }
            return [...prevState, menu]
        });
    },[])
    const handleMenuNavigation = (url: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault(); // 상태 업데이트 후 라우팅을 제어하기 위해 preventDefault 사용
        handleMenuClick(url); // 상태 업데이트
        setTimeout(() => {
            window.location.href = url; // 상태 업데이트 후 페이지 전환
        }, 0);
    };
    return (
        <div className="layout_wrap">
            <Header/>
            <div className="side_menu_wrap">
                <ul id="menu_list">
                    {memoizedMenuList.map(item => (
                        <li key={item.id}>
                            <Link
                                to={item.url}
                                onClick={handleMenuClick(item.id)}
                                className={classNames({active: activeMenu.includes(item.id)})}>
                                <item.icon/>
                                <div className="menu_item_title">{item.name}</div>
                                <div className="menu_item_arrow">
                                    {item.children && (activeMenu.includes(item.id) ? <IoIosArrowDown/> : <IoIosArrowUp/>)}
                                </div>
                            </Link>
                            {item.children && (
                                <div className={classNames('sub_menu_wrap', {active: activeMenu.includes(item.id)})}>
                                    <ul className="menu_sub_list">
                                        {item.children.map(child => {
                                            return (
                                                <li key={child.id}>
                                                    <Link
                                                        to={child.url}
                                                        onClick={handleMenuClick(child.id)}
                                                        className={classNames({active: activeMenu.includes(child.id)})}>
                                                        <child.icon/>
                                                        <div className="menu_item_title">{child.name}</div>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="contents_wrap">
                <Outlet/>
            </div>
        </div>

    )
}
