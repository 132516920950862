import React, {lazy} from "react";
import {Link, Outlet} from 'react-router-dom'
import "@assets/css/viewer/viewer.css"
const StudyButton = lazy(() => import("@component/viewer/StudyButton"));
const ToolGroup = lazy(() => import("@component/viewer/ToolGroup"));
export default function LayoutViewer() {

    return (
        <div id="viewerWrapper">
            <div className="viewer_wrap">
                <div className="viewer_fnc">
                    <StudyButton/>
                    <ToolGroup/>
                </div>
                <Outlet/>

            </div>
        </div>

    )
}
