import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOnDev, onError } from '@lib/function';
import { getUserData, useUserData } from '@store/user/store';

const BASE_URL = process.env.REACT_APP_API_URL;

const client = axios.create({
    baseURL: `${BASE_URL}/api`,
    withCredentials: false,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
    },
});

export const setHeaderToken = (token: string) => {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
    delete client.defaults.headers.common.Authorization;
};

const postRefreshToken = () => client.post('/user/auth/refresh', { refresh_token: 'refresh_token' });

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = getUserData().token
    // console.log(token)
    // console.log(config)
    // console.log(config.headers.Authorization)
    // console.log(client.defaults.headers.common.Authorization)
    if(!config.headers.Authorization && token){
       setHeaderToken(token);
       config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.method === 'get') {
        config.params = { ...config.params, _t: Date.now() };
        config.paramsSerializer = {
            serialize: (params) => new URLSearchParams(Object.entries(params).flatMap(([key, value]) =>
                Array.isArray(value) ? value.map(v => [key, v]) : [[key, value]]
            )).toString()
        };
    }
    return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    const { method, url } = response.config;
    const { status } = response;
    logOnDev(`👻👻👻 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);
    return response;
};

export const AxiosInterceptor: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const token = useUserData((state) => state.token);
    const navigate = useNavigate();

    const onErrorResponse = useCallback(async (error: AxiosError | Error): Promise<never> => {
        if (axios.isAxiosError(error)) {
            const { message, config, response } = error;
            const { method, url } = config || {};
            const { status, statusText } = response || {};

            if (status === 401) {
                try {
                    const tokenResponse = await postRefreshToken();
                    if (tokenResponse.status === 201) {
                        console.log('Token refreshed successfully');
                    }
                } catch (refreshError) {
                    if (axios.isAxiosError(refreshError) &&
                        (refreshError.response?.status === 404 || refreshError.response?.status === 402)) {
                        const actions = getUserData().actions;
                        actions.setUserData(null);
                        navigate('/login');
                    }
                }
            }

            logOnDev(`🚧🚧🚧 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${statusText} | ${message}`);
        } else {
            logOnDev(`🚧🚧🚧 [API] Error ${error.message}`);
            await onError(error.message);
        }
        return Promise.reject(error);
    }, [navigate]);

    useEffect(() => {
        const requestInterceptor = client.interceptors.request.use(onRequest);
        const responseInterceptor = client.interceptors.response.use(onResponse, onErrorResponse);

        return () => {
            client.interceptors.request.eject(requestInterceptor);
            client.interceptors.response.eject(responseInterceptor);
            removeHeaderToken();
        };
    }, [onErrorResponse]);

    return children;
};

export default client;
