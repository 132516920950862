import React, {lazy, Suspense} from 'react';
import { Route, Routes } from "react-router-dom";
import '@hoc/Auth'
import '@assets/css/common/default.css'
import '@assets/css/common/common.css'
import Login from "@pages/user/Login";
import Auth from "@hoc/Auth";
import Layout from "@layout/Layout";
import LayoutViewer from "@layout/LayoutViewer";
import Viewer from "@pages/viewer/Viewer";

function App() {
    const LoginPage = Auth(Login, false, false)
    const LayoutPage = Auth(Layout, true, false)
    const LayoutViewerPage = Auth(LayoutViewer, true, false)
    const WorkListPage = lazy(() => import("@pages/workList/WorkList"));
    const ReadingPage = lazy(() => import("@component/reading/ReadingList"));
    const PayPage = lazy(() => import("@component/pay/PayList"));
    const NotFoundPage = lazy(() => import("@pages/error/NotFound"));
    const ViewerPage = lazy(() => import("@pages/viewer/Viewer"));
    const CenterPage = lazy(() => import("@pages/manage/Center"));
  return (
   <Suspense fallback={null}>
     <Routes>
         <Route path='/' element={<LayoutPage/>}>
             <Route index element={<ReadingPage/>}/>
             <Route path='reading' element={<ReadingPage/>}/>
             <Route path='payment' element={<PayPage/>}/>
             <Route path='manage/'>
                 <Route path='user'/>
                 <Route path='center' element={<CenterPage/>}/>
             </Route>
         </Route>
         <Route path="/login" element={<LoginPage/>} />
         <Route path="*" element={<NotFoundPage/>}/>
     </Routes>
   </Suspense>
  );
}

export default App;
